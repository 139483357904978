.pm-main-app-login-success {
  color: #505050;
  line-height: 24px;
  text-align: center;
  margin-top: 10px;
  align-content: center;
}

.pm-main-app-login-success > h1 {
  font-size: 26px;
}

.pm-main-app-login-success > p {
  font-size: 18px;
  margin: 0;
}

.pm-main-app-login-success > p > a {
    color: #007bff;
    text-decoration: #007bff underline;
    font-size: 25px;
}